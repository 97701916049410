import SpeedIcon from '../../../../assets/images/views/interactive_view_section/icons/vitesse.svg'
import CartographicReportIcon from '../../../../assets/images/views/interactive_view_section/icons/rapportcartographique.svg'
import CartographicReportImage from '../../../../assets/images/views/interactive_view_section/desktop_images/cuma-rapportcartographique/cuma-rapportcartographique.png'
import CartographicReportImageLowDef from '../../../../assets/images/views/interactive_view_section/desktop_images/cuma-rapportcartographique/cuma-rapportcartographique_low_def.png'
import PatchyImportIcon from '../../../../assets/images/views/interactive_view_section/icons/importparcellaire.svg';
import DataSharingIcon from '../../../../assets/images/views/interactive_view_section/icons/partagededonnees.svg';
import LinkToIcon from '../../../../assets/images/views/interactive_view_section/icons/lienvers.svg';

const interactiveDesktopData = {
	speed: {
		icon: SpeedIcon,
		label: 'Vitesse des matériels',
		description: `Localisez les matériels de votre CUMA en toute simplicité;

    et visualisez l'activité de chacun d'entre eux d'un simple coup d'œil`,
		correspondingViewImage: CartographicReportImage,
		correspondingViewImageLowDef: CartographicReportImageLowDef
	},
	cartographicReport: {
		icon: CartographicReportIcon,
		label: 'Rapport cartographique',
		description: `Localisez les matériels de votre CUMA en toute simplicité;

    et visualisez l'activité de chacun d'entre eux d'un simple coup d'œil`,
		correspondingViewImage: CartographicReportImage,
		correspondingViewImageLowDef: CartographicReportImageLowDef
	},
	patchyImport: {
		icon: PatchyImportIcon,
		label: 'Import du parcellaire',
		description: `Localisez les matériels de votre CUMA en toute simplicité;

    et visualisez l'activité de chacun d'entre eux d'un simple coup d'œil`,
		correspondingViewImage: CartographicReportImage,
		correspondingViewImageLowDef: CartographicReportImageLowDef
	},
	dataSharing: {
		icon: DataSharingIcon,
		label: 'Partage de données',
		description: `Localisez les matériels de votre CUMA en toute simplicité;

    et visualisez l'activité de chacun d'entre eux d'un simple coup d'œil`,
		correspondingViewImage: CartographicReportImage,
		correspondingViewImageLowDef: CartographicReportImageLowDef
	},
	linkTo: {
		icon: LinkToIcon,
		label: 'Lien vers My CUMA',
		description: `Localisez les matériels de votre CUMA en toute simplicité;

    et visualisez l'activité de chacun d'entre eux d'un simple coup d'œil`,
		correspondingViewImage: CartographicReportImage,
		correspondingViewImageLowDef: CartographicReportImageLowDef
	}
}

export default interactiveDesktopData
