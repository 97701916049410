import React from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import InteractiveViewSection from '../../../small_views/interactive_view_section/interactive_view_section';

import data from './cuma_interaction_phone_data';

import classes from './cuma_interaction_phone_section.module.css';

const CumaInteractivePhoneSection = () => {
	const displayDownloadsLinks = useMediaQuery('(max-width: 1300px)', { defaultMatches: true });
	return (
		<InteractiveViewSection
			title="Une application modelée pour la gestion de vos ressources agricoles"
			viewType="phone"
			showDownloadsLinks={displayDownloadsLinks}
			viewDeviceProps={{
				className: classes.viewDevice
			}}
			{...{ data }}
		/>
	);
};

export default CumaInteractivePhoneSection;
