import JeromeLoremipsumPicture from '../../../../assets/images/pages/solutions/cuma/testimonials_section/jerome_loremipsum/jerome_loremipsum.png';
import JeromeLoremipsumPictureLowDef from '../../../../assets/images/pages/solutions/cuma/testimonials_section/jerome_loremipsum/jerome_loremipsum_low_def.png';

import FrancoisDeleauPicture from '../../../../assets/images/pages/solutions/cuma/testimonials_section/francois_deleau/francois_deleau.png';
import FrancoisDeleauPictureLowDef from '../../../../assets/images/pages/solutions/cuma/testimonials_section/francois_deleau/francois_deleau_low_def.png';

export default {
	jeromeLoremipsum: {
		url: 'https://www.youtube.com/watch?v=IgnfKt6yojM',
		tag: 'cuma des 3 cantons',
		name: 'Jérôme LOREMIPSUM',
		title: 'Président de la CUMA',
		preview: JeromeLoremipsumPicture,
		previewLowDef: JeromeLoremipsumPictureLowDef,
    videoDuration: '3:05'
	},
	francoisDeleau: {
		url: 'https://www.youtube.com/watch?v=q8IUpZt0ros',
		tag: 'CUMA Ternois',
		name: 'François DELEAU',
		title: 'Agriculteur',
		preview: FrancoisDeleauPicture,
		previewLowDef: FrancoisDeleauPictureLowDef,
    videoDuration: '2:22'
	}
};
