import React from 'react';

import HelmetLayout from '../../components/layouts/helmet_layout/helmet_layout';
import MainLayout from '../../components/layouts/main_layout/main_layout';
import WithGradientLayout from '../../components/layouts/with_gradient_layout/with_gradient_layout';

import CumaHeader from '../../components/solutions/cuma/cuma_header/cuma_header';
import CumaInteractivePhoneSection from '../../components/solutions/cuma/cuma_interactive_phone_section/cuma_interactive_phone_section';
import CumaInteractiveDesktopSection from '../../components/solutions/cuma/cuma_interactive_desktop_section/cuma_interactive_desktop_section';
import CumaTestimonialsSection from '../../components/solutions/cuma/cuma_testimonials_section/cuma_testimonials_section';

const Cuma = () => (
	<HelmetLayout>
		<MainLayout
			footerProps={{
				dismissedSections: ['create-demo-account']
			}}
		>
			<CumaHeader />
			<WithGradientLayout>
				<CumaInteractivePhoneSection />
				<CumaInteractiveDesktopSection />
			</WithGradientLayout>
			<CumaTestimonialsSection />
		</MainLayout>
	</HelmetLayout>
);

export default Cuma;
